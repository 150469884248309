/*remove error overlay*/
/* iframe
{
    display: none;
} */
.modal-content{
  overflow: hidden;
  box-shadow: 0px 0px 40px -5px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 0px 40px -5px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 0px 40px -5px rgba(0,0,0,0.5);
}
/*layout*/
.inner-wrapper{
  min-height:100vh;
  flex-direction: column;
  align-items: center;
}
.content-header{
  transform: translateY(25%);
  color:#414141;
}
.main-content{
  height:calc(100%-120px);
  color:#414141;
}
.wrapper {
  min-height:100vh;
  width:100%;
  background-color:#eee;
  flex-direction: column;
  align-items: center;
}
.bg-image{
  /* background-image:url('./media/bg-1.jpg'); */
  background-color:#eee;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.container{
  height:100%;
  align-items:center;
}
.btn-close {
  position: relative;
  float:right;
  right:10px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: transparent; 
}
::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary-dark);
  border-radius: 4px;
  border: 0px solid transparent;
}
/*style classes, by 5 px*/
.pad1{
  padding:5px !important;
}
.pad2{
  padding:10px !important;
}
.pad3{
  padding:15px !important;
}
.padL1{
  padding-left: 5px !important;
}
.padL2{
  padding-left: 10px !important;
}
.padL3{
  padding-left: 15px !important;
}
.padR1{
  padding-right: 5px !important;
}
.padR2{
  padding-right: 10px !important;
}
.padR3{
  padding-right: 15px !important;
}
.padT1{
  padding-top: 5px !important;
}
.padT2{
  padding-top: 10px !important;
}
.padT3{
  padding-top: 15px !important;
}
.padB1{
  padding-bottom: 5px !important;
}
.padB2{
  padding-bottom: 10px !important;
}
.padB3{
  padding-bottom: 15px !important;
}
.mrgnL1{
  margin-left: 5px !important;
}
.mrgnL2{
  margin-left: 10px !important;
}
.mrgnL3{
  margin-left: 15px !important;
}
.mrgnR1{
  margin-right: 5px !important;
}
.mrgnR2{
  margin-right: 10px !important;
}
.mrgnR3{
  margin-right: 15px !important;
}
.mrgnT1{
  margin-top: 5px !important;
}
.mrgnT2{
  margin-top: 10px !important;
}
.mrgnT3{
  margin-top: 15px !important;
}
.mrgnB1{
  margin-bottom: 5px !important;
}
.mrgnB2{
  margin-bottom: 10px !important;
}
.mrgnB3{
  margin-bottom: 15px !important;
}
.c-pointer{
  cursor:pointer;
}
.float-r{
  float:right;
}
.border-B1-p{
  border-bottom: 1px solid var(--bs-primary);
}
.border-B2-p{
  border-bottom: 2px solid var(--bs-primary);
}
.border-B1-pd{
  border-bottom: 1px solid var(--bs-primary-dark);
}
.border-T1-p{
  border-top: 1px solid var(--bs-primary);
}
.fill-x{
  width:100%;
}
.inline{
  display:inline;
}
