.row.tile-wrapper{
    padding:30px;
    margin:30px;
    border-radius: 20px;
    border: 1px solid var(--bs-primary);
}
.budget-doughnut-wrapper{
    border:1px dashed var(--bs-primary);
    margin:5px;
    padding:5px;
    width:200px;
    display:inline-block;
}